/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AnnualReport,
  Corporation,
  Officer,
  PaginatedAnnualReportList,
  PaginatedCorporationList,
  PaginatedOfficerList,
  PatchedAnnualReport,
  PatchedCorporation,
  PatchedOfficer,
} from '../models/index';
import {
    AnnualReportFromJSON,
    AnnualReportToJSON,
    CorporationFromJSON,
    CorporationToJSON,
    OfficerFromJSON,
    OfficerToJSON,
    PaginatedAnnualReportListFromJSON,
    PaginatedAnnualReportListToJSON,
    PaginatedCorporationListFromJSON,
    PaginatedCorporationListToJSON,
    PaginatedOfficerListFromJSON,
    PaginatedOfficerListToJSON,
    PatchedAnnualReportFromJSON,
    PatchedAnnualReportToJSON,
    PatchedCorporationFromJSON,
    PatchedCorporationToJSON,
    PatchedOfficerFromJSON,
    PatchedOfficerToJSON,
} from '../models/index';

export interface AnnualReportListRequest {
    page?: number;
}

export interface AnnualReportRetrieveRequest {
    id: number;
}

export interface CorporationCreateRequest {
    corporation: Omit<Corporation, 'id'|'cleaned_contact_name'|'cleaned_contact_last_name'>;
}

export interface CorporationDestroyRequest {
    id: number;
}

export interface CorporationListRequest {
    page?: number;
}

export interface CorporationPartialUpdateRequest {
    id: number;
    patchedCorporation?: Omit<PatchedCorporation, 'id'|'cleaned_contact_name'|'cleaned_contact_last_name'>;
}

export interface CorporationRetrieveRequest {
    id: number;
}

export interface CorporationUpdateRequest {
    id: number;
    corporation: Omit<Corporation, 'id'|'cleaned_contact_name'|'cleaned_contact_last_name'>;
}

export interface FloridaApiAnnualReportsCreateRequest {
    annualReport: Omit<AnnualReport, 'id'|'filing_status_display'|'jurisdiction_display'|'payment_status_display'|'report_name_display'|'created_at'|'updated_at'|'cleaned_contact_name'|'cleaned_contact_last_name'>;
}

export interface FloridaApiAnnualReportsCustomUpdateCreateRequest {
    id: number;
    annualReport: Omit<AnnualReport, 'id'|'filing_status_display'|'jurisdiction_display'|'payment_status_display'|'report_name_display'|'created_at'|'updated_at'|'cleaned_contact_name'|'cleaned_contact_last_name'>;
}

export interface FloridaApiAnnualReportsDestroyRequest {
    id: number;
}

export interface FloridaApiAnnualReportsPartialUpdateRequest {
    id: number;
    patchedAnnualReport?: Omit<PatchedAnnualReport, 'id'|'filing_status_display'|'jurisdiction_display'|'payment_status_display'|'report_name_display'|'created_at'|'updated_at'|'cleaned_contact_name'|'cleaned_contact_last_name'>;
}

export interface FloridaApiAnnualReportsUpdateRequest {
    id: number;
    annualReport: Omit<AnnualReport, 'id'|'filing_status_display'|'jurisdiction_display'|'payment_status_display'|'report_name_display'|'created_at'|'updated_at'|'cleaned_contact_name'|'cleaned_contact_last_name'>;
}

export interface OfficerCreateRequest {
    officer: Omit<Officer, 'id'>;
}

export interface OfficerDestroyRequest {
    id: number;
}

export interface OfficerListRequest {
    page?: number;
}

export interface OfficerPartialUpdateRequest {
    id: number;
    patchedOfficer?: Omit<PatchedOfficer, 'id'>;
}

export interface OfficerRetrieveRequest {
    id: number;
}

export interface OfficerUpdateRequest {
    id: number;
    officer: Omit<Officer, 'id'>;
}

/**
 * FloridaApi - interface
 * 
 * @export
 * @interface FloridaApiInterface
 */
export interface FloridaApiInterface {
    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloridaApiInterface
     */
    annualReportListRaw(requestParameters: AnnualReportListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedAnnualReportList>>;

    /**
     */
    annualReportList(requestParameters: AnnualReportListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedAnnualReportList>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Annual Report.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloridaApiInterface
     */
    annualReportRetrieveRaw(requestParameters: AnnualReportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnnualReport>>;

    /**
     */
    annualReportRetrieve(requestParameters: AnnualReportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnnualReport>;

    /**
     * 
     * @param {Corporation} corporation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloridaApiInterface
     */
    corporationCreateRaw(requestParameters: CorporationCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Corporation>>;

    /**
     */
    corporationCreate(requestParameters: CorporationCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Corporation>;

    /**
     * 
     * @param {number} id A unique integer value identifying this corporation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloridaApiInterface
     */
    corporationDestroyRaw(requestParameters: CorporationDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    corporationDestroy(requestParameters: CorporationDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloridaApiInterface
     */
    corporationListRaw(requestParameters: CorporationListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedCorporationList>>;

    /**
     */
    corporationList(requestParameters: CorporationListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedCorporationList>;

    /**
     * 
     * @param {number} id A unique integer value identifying this corporation.
     * @param {PatchedCorporation} [patchedCorporation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloridaApiInterface
     */
    corporationPartialUpdateRaw(requestParameters: CorporationPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Corporation>>;

    /**
     */
    corporationPartialUpdate(requestParameters: CorporationPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Corporation>;

    /**
     * 
     * @param {number} id A unique integer value identifying this corporation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloridaApiInterface
     */
    corporationRetrieveRaw(requestParameters: CorporationRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Corporation>>;

    /**
     */
    corporationRetrieve(requestParameters: CorporationRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Corporation>;

    /**
     * 
     * @param {number} id A unique integer value identifying this corporation.
     * @param {Corporation} corporation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloridaApiInterface
     */
    corporationUpdateRaw(requestParameters: CorporationUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Corporation>>;

    /**
     */
    corporationUpdate(requestParameters: CorporationUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Corporation>;

    /**
     * 
     * @param {AnnualReport} annualReport 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloridaApiInterface
     */
    floridaApiAnnualReportsCreateRaw(requestParameters: FloridaApiAnnualReportsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnnualReport>>;

    /**
     */
    floridaApiAnnualReportsCreate(requestParameters: FloridaApiAnnualReportsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnnualReport>;

    /**
     * Custom action to handle a specific POST request to update a record.
     * @param {number} id A unique integer value identifying this Annual Report.
     * @param {AnnualReport} annualReport 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloridaApiInterface
     */
    floridaApiAnnualReportsCustomUpdateCreateRaw(requestParameters: FloridaApiAnnualReportsCustomUpdateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnnualReport>>;

    /**
     * Custom action to handle a specific POST request to update a record.
     */
    floridaApiAnnualReportsCustomUpdateCreate(requestParameters: FloridaApiAnnualReportsCustomUpdateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnnualReport>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Annual Report.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloridaApiInterface
     */
    floridaApiAnnualReportsDestroyRaw(requestParameters: FloridaApiAnnualReportsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    floridaApiAnnualReportsDestroy(requestParameters: FloridaApiAnnualReportsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Annual Report.
     * @param {PatchedAnnualReport} [patchedAnnualReport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloridaApiInterface
     */
    floridaApiAnnualReportsPartialUpdateRaw(requestParameters: FloridaApiAnnualReportsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnnualReport>>;

    /**
     */
    floridaApiAnnualReportsPartialUpdate(requestParameters: FloridaApiAnnualReportsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnnualReport>;

    /**
     * 
     * @param {number} id A unique integer value identifying this Annual Report.
     * @param {AnnualReport} annualReport 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloridaApiInterface
     */
    floridaApiAnnualReportsUpdateRaw(requestParameters: FloridaApiAnnualReportsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnnualReport>>;

    /**
     */
    floridaApiAnnualReportsUpdate(requestParameters: FloridaApiAnnualReportsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnnualReport>;

    /**
     * 
     * @param {Officer} officer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloridaApiInterface
     */
    officerCreateRaw(requestParameters: OfficerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Officer>>;

    /**
     */
    officerCreate(requestParameters: OfficerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Officer>;

    /**
     * 
     * @param {number} id A unique integer value identifying this officer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloridaApiInterface
     */
    officerDestroyRaw(requestParameters: OfficerDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    officerDestroy(requestParameters: OfficerDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloridaApiInterface
     */
    officerListRaw(requestParameters: OfficerListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedOfficerList>>;

    /**
     */
    officerList(requestParameters: OfficerListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedOfficerList>;

    /**
     * 
     * @param {number} id A unique integer value identifying this officer.
     * @param {PatchedOfficer} [patchedOfficer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloridaApiInterface
     */
    officerPartialUpdateRaw(requestParameters: OfficerPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Officer>>;

    /**
     */
    officerPartialUpdate(requestParameters: OfficerPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Officer>;

    /**
     * 
     * @param {number} id A unique integer value identifying this officer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloridaApiInterface
     */
    officerRetrieveRaw(requestParameters: OfficerRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Officer>>;

    /**
     */
    officerRetrieve(requestParameters: OfficerRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Officer>;

    /**
     * 
     * @param {number} id A unique integer value identifying this officer.
     * @param {Officer} officer 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FloridaApiInterface
     */
    officerUpdateRaw(requestParameters: OfficerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Officer>>;

    /**
     */
    officerUpdate(requestParameters: OfficerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Officer>;

}

/**
 * 
 */
export class FloridaApi extends runtime.BaseAPI implements FloridaApiInterface {

    /**
     */
    async annualReportListRaw(requestParameters: AnnualReportListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedAnnualReportList>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/florida/api/annual_reports/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedAnnualReportListFromJSON(jsonValue));
    }

    /**
     */
    async annualReportList(requestParameters: AnnualReportListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedAnnualReportList> {
        const response = await this.annualReportListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async annualReportRetrieveRaw(requestParameters: AnnualReportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnnualReport>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling annualReportRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/florida/api/annual_reports/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnualReportFromJSON(jsonValue));
    }

    /**
     */
    async annualReportRetrieve(requestParameters: AnnualReportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnnualReport> {
        const response = await this.annualReportRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async corporationCreateRaw(requestParameters: CorporationCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Corporation>> {
        if (requestParameters['corporation'] == null) {
            throw new runtime.RequiredError(
                'corporation',
                'Required parameter "corporation" was null or undefined when calling corporationCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/florida/api/corporations/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CorporationToJSON(requestParameters['corporation']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CorporationFromJSON(jsonValue));
    }

    /**
     */
    async corporationCreate(requestParameters: CorporationCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Corporation> {
        const response = await this.corporationCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async corporationDestroyRaw(requestParameters: CorporationDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling corporationDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/florida/api/corporations/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async corporationDestroy(requestParameters: CorporationDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.corporationDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async corporationListRaw(requestParameters: CorporationListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedCorporationList>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/florida/api/corporations/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedCorporationListFromJSON(jsonValue));
    }

    /**
     */
    async corporationList(requestParameters: CorporationListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedCorporationList> {
        const response = await this.corporationListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async corporationPartialUpdateRaw(requestParameters: CorporationPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Corporation>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling corporationPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/florida/api/corporations/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedCorporationToJSON(requestParameters['patchedCorporation']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CorporationFromJSON(jsonValue));
    }

    /**
     */
    async corporationPartialUpdate(requestParameters: CorporationPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Corporation> {
        const response = await this.corporationPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async corporationRetrieveRaw(requestParameters: CorporationRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Corporation>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling corporationRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/florida/api/corporations/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CorporationFromJSON(jsonValue));
    }

    /**
     */
    async corporationRetrieve(requestParameters: CorporationRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Corporation> {
        const response = await this.corporationRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async corporationUpdateRaw(requestParameters: CorporationUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Corporation>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling corporationUpdate().'
            );
        }

        if (requestParameters['corporation'] == null) {
            throw new runtime.RequiredError(
                'corporation',
                'Required parameter "corporation" was null or undefined when calling corporationUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/florida/api/corporations/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CorporationToJSON(requestParameters['corporation']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CorporationFromJSON(jsonValue));
    }

    /**
     */
    async corporationUpdate(requestParameters: CorporationUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Corporation> {
        const response = await this.corporationUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async floridaApiAnnualReportsCreateRaw(requestParameters: FloridaApiAnnualReportsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnnualReport>> {
        if (requestParameters['annualReport'] == null) {
            throw new runtime.RequiredError(
                'annualReport',
                'Required parameter "annualReport" was null or undefined when calling floridaApiAnnualReportsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/florida/api/annual_reports/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnnualReportToJSON(requestParameters['annualReport']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnualReportFromJSON(jsonValue));
    }

    /**
     */
    async floridaApiAnnualReportsCreate(requestParameters: FloridaApiAnnualReportsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnnualReport> {
        const response = await this.floridaApiAnnualReportsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Custom action to handle a specific POST request to update a record.
     */
    async floridaApiAnnualReportsCustomUpdateCreateRaw(requestParameters: FloridaApiAnnualReportsCustomUpdateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnnualReport>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling floridaApiAnnualReportsCustomUpdateCreate().'
            );
        }

        if (requestParameters['annualReport'] == null) {
            throw new runtime.RequiredError(
                'annualReport',
                'Required parameter "annualReport" was null or undefined when calling floridaApiAnnualReportsCustomUpdateCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/florida/api/annual_reports/{id}/custom-update/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnnualReportToJSON(requestParameters['annualReport']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnualReportFromJSON(jsonValue));
    }

    /**
     * Custom action to handle a specific POST request to update a record.
     */
    async floridaApiAnnualReportsCustomUpdateCreate(requestParameters: FloridaApiAnnualReportsCustomUpdateCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnnualReport> {
        const response = await this.floridaApiAnnualReportsCustomUpdateCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async floridaApiAnnualReportsDestroyRaw(requestParameters: FloridaApiAnnualReportsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling floridaApiAnnualReportsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/florida/api/annual_reports/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async floridaApiAnnualReportsDestroy(requestParameters: FloridaApiAnnualReportsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.floridaApiAnnualReportsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async floridaApiAnnualReportsPartialUpdateRaw(requestParameters: FloridaApiAnnualReportsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnnualReport>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling floridaApiAnnualReportsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/florida/api/annual_reports/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedAnnualReportToJSON(requestParameters['patchedAnnualReport']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnualReportFromJSON(jsonValue));
    }

    /**
     */
    async floridaApiAnnualReportsPartialUpdate(requestParameters: FloridaApiAnnualReportsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnnualReport> {
        const response = await this.floridaApiAnnualReportsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async floridaApiAnnualReportsUpdateRaw(requestParameters: FloridaApiAnnualReportsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnnualReport>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling floridaApiAnnualReportsUpdate().'
            );
        }

        if (requestParameters['annualReport'] == null) {
            throw new runtime.RequiredError(
                'annualReport',
                'Required parameter "annualReport" was null or undefined when calling floridaApiAnnualReportsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/florida/api/annual_reports/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AnnualReportToJSON(requestParameters['annualReport']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnnualReportFromJSON(jsonValue));
    }

    /**
     */
    async floridaApiAnnualReportsUpdate(requestParameters: FloridaApiAnnualReportsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnnualReport> {
        const response = await this.floridaApiAnnualReportsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async officerCreateRaw(requestParameters: OfficerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Officer>> {
        if (requestParameters['officer'] == null) {
            throw new runtime.RequiredError(
                'officer',
                'Required parameter "officer" was null or undefined when calling officerCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/florida/api/officers/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OfficerToJSON(requestParameters['officer']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfficerFromJSON(jsonValue));
    }

    /**
     */
    async officerCreate(requestParameters: OfficerCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Officer> {
        const response = await this.officerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async officerDestroyRaw(requestParameters: OfficerDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling officerDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/florida/api/officers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async officerDestroy(requestParameters: OfficerDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.officerDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async officerListRaw(requestParameters: OfficerListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedOfficerList>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/florida/api/officers/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedOfficerListFromJSON(jsonValue));
    }

    /**
     */
    async officerList(requestParameters: OfficerListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedOfficerList> {
        const response = await this.officerListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async officerPartialUpdateRaw(requestParameters: OfficerPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Officer>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling officerPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/florida/api/officers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedOfficerToJSON(requestParameters['patchedOfficer']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfficerFromJSON(jsonValue));
    }

    /**
     */
    async officerPartialUpdate(requestParameters: OfficerPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Officer> {
        const response = await this.officerPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async officerRetrieveRaw(requestParameters: OfficerRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Officer>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling officerRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/florida/api/officers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfficerFromJSON(jsonValue));
    }

    /**
     */
    async officerRetrieve(requestParameters: OfficerRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Officer> {
        const response = await this.officerRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async officerUpdateRaw(requestParameters: OfficerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Officer>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling officerUpdate().'
            );
        }

        if (requestParameters['officer'] == null) {
            throw new runtime.RequiredError(
                'officer',
                'Required parameter "officer" was null or undefined when calling officerUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/florida/api/officers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OfficerToJSON(requestParameters['officer']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfficerFromJSON(jsonValue));
    }

    /**
     */
    async officerUpdate(requestParameters: OfficerUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Officer> {
        const response = await this.officerUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
